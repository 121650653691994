//client1
import logo_SR from 'assets/images/logo/logo-dark.svg';
//client2
import logo_light_JMED from 'assets/images/judicemed/judicemed_horizontal_white.svg';
import logo_light_sm_JMED from 'assets/images/judicemed/judicemed_simples_white.svg';
import logo_dark_JMED_2 from 'assets/images/judicemed/judicemed_horizontal.svg';

import logo_dark_JMED from 'assets/images/judicemed/logo_dark.png';
import logo_dark_sm_JMED from 'assets/images/judicemed/logo_dark1.svg';
//client3
import logo_dark_EH from 'assets/images/eh/logo.png';       // change
import logo_dark_sm_EH from 'assets/images/eh/logo.png';   // change
// Client4
import logo_dark_CCARE from 'assets/images/cuidemecare/logo_full.png';
import logo_dark_sm_CCARE from 'assets/images/cuidemecare/logo_small.png';
import logo_dark_CCARE_2 from 'assets/images/cuidemecare/logo_full.png';
// Client5
// Client6
import logo_dark_GH from 'assets/images/guardian/logo_full.png';
import logo_dark_sm_GH from 'assets/images/guardian/logo_small.png';
import logo_dark_GH_2 from 'assets/images/guardian/logo_full.png';


export interface ClientConfig {
    id: string;
    session_key: string;
    api: {
        API_URL: string | undefined,
        DOMAIN: string | undefined,
    }
    theme: {
        primaryColor: string;
        secondaryColor: string;
        logo: string;
        logoLightSm: string;
        logoDark: string;
        logoDarkSm: string;
        logoDarkAlt: string;
        companyName: string;
        defaultLang: string;
    };
    features: {
        [key: string]: boolean;
    };
    routes: {
        [key: string]: boolean;
    };
    languages: {
        [key: string]: boolean;
    };
}

const CLIENT_1: ClientConfig = {
    id: 'client1',
    session_key: 'eh-cl1_admin',
    api: {
        API_URL: process.env.REACT_APP_API_URL,
        DOMAIN: process.env.REACT_APP_CLIENT_ID
    },
    theme: {
        primaryColor: '#ff0000',
        secondaryColor: '#00ff00',
        logo: logo_SR,
        logoLightSm: logo_SR,
        logoDarkSm: logo_SR,
        logoDark: logo_SR,
        logoDarkAlt: logo_SR,
        companyName: 'Sanitas Remota',
        defaultLang: 'en'
    },
    features: {
        activity: true,
        summary: true,
        alerts: true,
        systemAlerts: true,
        profile: true,
        patients: true,
        doctors: true,
        admins: true,
        createClient: false,
        createUser: true,
        createDoc: false,
        createAdmin: false,
        createUserBatch: false,
        userAssigment: false,
        campaignList: true,
        createCampaign: true,
        termsAndConditions: false,
    },
    routes: {
        analytics: true,
        gadgets: true,
        notifications: true,
        params: true,
        account: true,
        users: true,
        campaigns: true,
    },
    languages: {
        multi: true,
        spanish: true,
        english: true,
        portuguese: true
    }
};

const CLIENT_2: ClientConfig = {
    id: 'client2',
    session_key: 'eh-cl2_admin',
    api: {
        API_URL: process.env.REACT_APP_API_URL,
        DOMAIN: process.env.REACT_APP_CLIENT_ID
    },
    theme: {
        primaryColor: '#ff0000',
        secondaryColor: '#00ff00',
        logo: logo_light_JMED,
        logoLightSm: logo_light_sm_JMED,
        logoDark: logo_dark_JMED,
        logoDarkSm: logo_dark_sm_JMED,
        logoDarkAlt: logo_dark_JMED_2,
        companyName: 'Judicemed',
        defaultLang: 'br'
    },
    features: {
        activity: true,
        summary: true,
        alerts: true,
        systemAlerts: true,
        profile: true,
        patients: true,
        doctors: true,
        admins: true,
        createClient: false,
        createUser: true,
        createDoc: true,
        createAdmin: true,
        createUserBatch: true,
        userAssigment: true,
        campaignList: false,
        createCampaign: false,
        termsAndConditions: true,
    },
    routes: {
        analytics: true,
        gadgets: true,
        notifications: true,
        params: true,
        account: true,
        users: true,
        campaigns: true,
    },
    languages: {
        multi: false,
        spanish: false,
        english: false,
        portuguese: true
    }
};

const CLIENT_3: ClientConfig = {
    id: 'client3',
    session_key: 'eh-cl3_admin',
    api: {
        API_URL: process.env.REACT_APP_API_URL,
        DOMAIN: process.env.REACT_APP_CLIENT_ID
    },
    theme: {
        primaryColor: '#ff0000',    // #dd8986
        secondaryColor: '#00ff00',
        logo: logo_dark_EH,         // check/change
        logoLightSm: logo_dark_EH,  // check/change
        logoDark: logo_dark_EH,     // check/change
        logoDarkSm: logo_dark_EH,   // check/change
        logoDarkAlt: logo_dark_EH,  // check/change
        companyName: 'Emmitec.Health',
        defaultLang: 'en'
    },
    features: {
        activity: true,
        summary: true,
        alerts: true,
        systemAlerts: true,
        profile: true,
        patients: true,
        doctors: true,
        admins: true,
        createClient: false,
        createUser: true,
        createDoc: false,
        createAdmin: false,
        createUserBatch: false,
        userAssigment: false,
        campaignList: true,
        createCampaign: true,
        termsAndConditions: false,
    },
    routes: {
        analytics: true,
        gadgets: true,
        notifications: true,
        params: true,
        account: true,
        users: true,
        campaigns: true,
    },
    languages: {
        multi: true,
        spanish: true,
        english: true,
        portuguese: true
    }
};

const CLIENT_4: ClientConfig = {
    id: 'client4',
    session_key: 'eh-cl4_admin',
    api: {
        API_URL: process.env.REACT_APP_API_URL,
        DOMAIN: process.env.REACT_APP_CLIENT_ID
    },
    theme: {
        primaryColor: '#ff0000',
        secondaryColor: '#00ff00',
        logo: logo_dark_CCARE,
        logoLightSm: logo_dark_sm_CCARE,
        logoDark: logo_dark_CCARE,
        logoDarkSm: logo_dark_sm_CCARE,
        logoDarkAlt: logo_dark_CCARE,
        companyName: 'Cuideme.Care',
        defaultLang: 'br'
    },
    features: {
        activity: true,
        summary: true,
        alerts: true,
        systemAlerts: true,
        profile: true,
        patients: true,
        doctors: true,
        admins: true,
        createClient: false,
        createUser: true,
        createDoc: true,
        createAdmin: true,
        createUserBatch: false,
        userAssigment: true,
        campaignList: false,
        createCampaign: false,
        termsAndConditions: true,
    },
    routes: {
        analytics: true,
        gadgets: true,
        notifications: true,
        params: true,
        account: true,
        users: true,
        campaigns: true,
    },
    languages: {
        multi: false,
        spanish: false,
        english: false,
        portuguese: true
    }
};

const CLIENT_6: ClientConfig = {
    id: 'client6',
    session_key: 'eh-cl6_admin',
    api: {
        API_URL: process.env.REACT_APP_API_URL,
        DOMAIN: process.env.REACT_APP_CLIENT_ID
    },
    theme: {
        primaryColor: '#ff0000',
        secondaryColor: '#00ff00',
        logo: logo_dark_GH,
        logoLightSm: logo_dark_sm_GH,
        logoDark: logo_dark_GH,
        logoDarkSm: logo_dark_sm_GH,
        logoDarkAlt: logo_dark_GH_2,
        companyName: 'Guardian Health',
        defaultLang: 'br'
    },
    features: {
        activity: true,
        summary: true,
        alerts: true,
        systemAlerts: true,
        profile: true,
        patients: true,
        doctors: true,
        admins: true,
        createClient: false,
        createUser: true,
        createDoc: true,
        createAdmin: true,
        createUserBatch: true,
        userAssigment: true,
        campaignList: false,
        createCampaign: false,
        termsAndConditions: true,
    },
    routes: {
        analytics: true,
        gadgets: true,
        notifications: true,
        params: true,
        account: true,
        users: true,
        campaigns: true,
    },
    languages: {
        multi: false,
        spanish: false,
        english: false,
        portuguese: true
    }
};

const configs: { [key: string]: ClientConfig } = {
    client1: CLIENT_1,
    client2: CLIENT_2,
    client3: CLIENT_3,
    client4: CLIENT_4,
    client6: CLIENT_6,
};

type ClientKey = keyof typeof configs;

const clientKey = (process.env.REACT_APP_CLIENT || 'client1') as ClientKey;
export const activeConfig: ClientConfig = configs[clientKey];
